import React from 'react';
import ReactDOM from 'react-dom';
import { GustoSiteSearch } from '@gusto/niffler';

const mountPoints = Array.from(document.querySelectorAll('[id=site-search]'));
const root = document.documentElement;
const fullNavbar = document.querySelector('#gusto-navbar');

// Adjusts height of search container to display navbar + banner
const setSearchContainerHeight = fullHeader => {
  const fullHeaderHeight = window.getComputedStyle(fullHeader).height;
  root.style.setProperty('--search-niffler-container-height', fullHeaderHeight);
};

// This custom events comes from notification_bar.js to listen for when the notification banner is showing/hiding
window.addEventListener('notification-bar-change', () => setSearchContainerHeight(fullNavbar));

window.addEventListener('resize', () => setSearchContainerHeight(fullNavbar));

if (mountPoints.length > 0) {
  const body = document.querySelector('body');
  const navBar = document.querySelector('.navbar-collapse');
  const mobileOpener = document.querySelector('.menu-icon');

  const handleResultsPanelClose = () => {
    body.classList.remove('overflow-hidden-y');
  };

  const handleResultsPanelOpen = () => {
    body.classList.add('overflow-hidden-y');
    navBar.classList.remove('in');
    mobileOpener.classList.remove('open');
  };

  const gustoSiteSearch = React.createElement(GustoSiteSearch, {
    resultsContainerId: 'site-search-results',
    onResultsPanelClose: handleResultsPanelClose,
    onResultsPanelOpen: handleResultsPanelOpen,
  });

  mountPoints.forEach(mountPoint => {
    ReactDOM.render(gustoSiteSearch, mountPoint);
  });
}
